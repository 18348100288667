import axios from "axios";
import store from '@/store/index'
import { computed } from 'vue';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {refresh} from "@/repositories/AuthHelper";


export default function () {
    const token = computed(() => store.state.token)

    const api = axios.create({
        baseURL: process.env.VUE_APP_BASE_API_URL,
        headers: {
            'Authorization': `Bearer ${token.value}`
        }
    })

    // Automatically retry handling for expired auth JWTs
    const refreshAuthLogic = (failedRequest: any) => refresh().then(() => {
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + token.value
        return Promise.resolve();
    })

    // Intercept 401 and 403 responses and put them through the refresh handler above
    createAuthRefreshInterceptor(api, refreshAuthLogic, {statusCodes: [ 401, 403 ]});

    return api
}
