<template>
    <va-navbar color="primary">
        <template #left>
            <va-navbar-item v-if="!($route.name === 'Home')">
                <router-link to="/" v-if="$route.meta['public'] || false">
                    <img class="logo" alt="Chat Libs! Logo" src="../assets/cl_logo_horizontal_white.svg">
                </router-link>
                <router-link :to="{name: 'ManagementDashboard'}" v-else>
                    <img class="logo" alt="Chat Libs! Logo" src="../assets/cl_logo_horizontal_white.svg">
                </router-link>
            </va-navbar-item>
        </template>
        <template #right>
            <navbar-actions
                v-if="isLoggedIn"
                :profile-image-url="profileImageUrl"
                :user-name="userName"
                class="app-navbar__actions md5 lg4"
            />

            <va-navbar-item v-if="!isLoggedIn">
                <router-link class="fakelink" :to="{name: 'Login'}">Log In/Sign Up</router-link>
            </va-navbar-item>
        </template>
    </va-navbar>
</template>

<script>
import NavbarActions from "@/components/NavbarActions";

export default {
    name: "Navbar",
    components: {
        NavbarActions,
    },
    props: {
        userName: String,
        profileImageUrl: String,
        isLoggedIn: Boolean,
    }
}
</script>

<style lang="scss" scoped>
.va-navbar {
    box-shadow: var(--va-box-shadow);
    z-index: 2;
    height: 3.5rem;
    //background-color: #EBF1F6 !important;

    &__center {
        @media screen and (max-width: 950px) {
            .app-navbar__text {
                display: none;
            }
        }
    }

    @media screen and (max-width: 950px) {
        .left {
            width: 100%;
        }
        .app-navbar__actions {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}

.va-navbar__item > .fakelink {
    color: white;
    cursor: pointer;
}

.left {
    display: flex;
    align-items: center;

    & > * {
        margin-right: 1.5rem;
    }

    & > *:last-child {
        margin-right: 0;
    }
}

.x-flip {
    transform: scaleX(-100%);
}

.app-navbar__text > * {
    margin-right: 0.5rem;

    &:last-child {
        margin-right: 0;
    }
}

.logo {
    height: 30px;
}
</style>