import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import store from "@/store";
import PublicParent from "@/views/PublicParent.vue";
import NotFound from "@/views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'PublicParent',
        component: PublicParent,
        meta: {
            title: ""
        },
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import(/* webpackChunkName: "public" */ '@/views/public/Home.vue'),
                meta: {
                    public: true,
                    title: "Home"
                }
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import(/* webpackChunkName: "public" */ '@/views/public/Login.vue'),
                meta: {
                    public: true,
                    title: "Log In"
                }
            },
            {
                path: 'about',
                name: 'About',
                component: () => import(/* webpackChunkName: "about" */ '@/views/public/About.vue'),
                meta: {
                    public: true,
                    title: "About Us"
                }
            },
            {
                path: 'legal/terms',
                name: 'TermsOfService',
                component: () => import(/* webpackChunkName: "legal" */ '@/views/public/Terms.vue'),
                meta: {
                    public: true,
                    title: "Terms Of Service"
                }
            },
            {
                path: 'legal/privacy',
                name: 'PrivacyPolicy',
                component: () => import(/* webpackChunkName: "legal" */ '@/views/public/Privacy.vue'),
                meta: {
                    public: true,
                    title: "Privacy Policy"
                }
            },
            {
                path: 'faq',
                name: 'FAQ',
                component: () => import(/* webpackChunkName: "legal" */ '@/views/public/Faq.vue'),
                meta: {
                    public: true,
                    title: "Frequently Asked Questions"
                }
            },
        ]
    },
    {
        path: '/connections/twitch/callback',
        name: 'TwitchCallback',
        component: () => import('../views/TwitchCallback.vue')
    },
    {
        path: '/management',
        name: 'ManagementParent',
        component: () => import(/* webpackChunkName: "management" */ '@/views/ManagementParent.vue'),
        meta: {
            requiresAuth: true,
            title: "Management"
        },
        children: [
            {
                path: '',
                name: 'ManagementDashboard',
                component: () => import(/* webpackChunkName: "management" */ '@/views/management/ManagementDashboard.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Dashboard"
                }
            },
            {
                path: 'config/channel',
                name: 'ChannelConfig',
                component: () => import(/* webpackChunkName: "management" */ '@/views/management/ChannelConfig.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Channel Settings"
                }
            },
            {
                path: 'config/editors',
                name: 'ChannelEditors',
                component: () => import(/* webpackChunkName: "management" */ '@/views/management/ChannelEditors.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Channel Editors"
                }
            },
            {
                path: 'switch',
                name: 'ChannelSwitch',
                component: () => import(/* webpackChunkName: "management" */ '@/views/management/ChannelSwitch.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Switch Channels"
                }
            },
            {
                path: 'library',
                name: 'StoryLibraryList',
                component: () => import(/* webpackChunkName: "library" */ '@/views/management/StoryLibraryList.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Story Library"
                }
            },
            {
                path: 'library/:id',
                name: 'StoryLibraryView',
                component: () => import(/* webpackChunkName: "library" */ '@/views/management/StoryLibraryView.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    title: "Story Viewer"
                }
            },
            {
                path: 'library/story/edit',
                name: 'StoryEditor',
                component: () => import(/* webpackChunkName: "library" */ '@/views/management/StoryEditor.vue'),
                meta: {
                    requiresAuth: true,
                    title: "New Story"
                }
            },
            {
                path: 'library/story/edit/:id',
                name: 'StoryEditorEdit',
                component: () => import(/* webpackChunkName: "library" */ '@/views/management/StoryEditor.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    title: "Edit Story"
                }
            },
            {
                path: 'playlist',
                name: 'StoryPlaylist',
                component: () => import(/* webpackChunkName: "management" */ '@/views/management/StoryPlaylist.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Story Playlist"
                }
            },
            {
                path: 'history',
                name: 'StoryHistoryList',
                component: () => import(/* webpackChunkName: "management" */ '@/views/management/StoryHistoryList.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Story History"
                }
            },
            {
                path: 'history/:id',
                name: 'StoryHistoryView',
                component: () => import(/* webpackChunkName: "management" */ '@/views/management/StoryHistoryView.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    title: "Story History"
                }
            }
        ]
    },
    {
        path: '/admin',
        name: 'AdminParent',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminParent.vue'),
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            title: "Admin"
        },
        children: [
            {
                path: '',
                name: 'AdminDashboard',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminDashboard.vue'),
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Dashboard"
                }
            },
            {
                path: 'control',
                name: 'AdminControl',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminControl.vue'),
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Control"
                }
            },
            {
                path: 'library/story',
                name: 'AdminStoryLibraryList',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminStoryLibraryList.vue'),
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Story Library"
                }
            },
            {
                path: 'library/story/:id',
                name: 'AdminStoryLibraryView',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminStoryLibraryView.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Story View"
                }
            },
            {
                path: 'library/story/:id/edit',
                name: 'AdminStoryLibraryEdit',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminStoryEditor.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Story Edit"
                }
            },
            {
                path: 'channels',
                name: 'AdminChannelList',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminChannelList.vue'),
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Channels"
                }
            },
            {
                path: 'channels/:id',
                name: 'AdminChannelView',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminChannelView.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Channel View"
                }
            },
            {
                path: 'faq',
                name: 'AdminFaqList',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminFaqList.vue'),
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - FAQ"
                }
            },
            {
                path: 'faq/edit',
                name: 'AdminFaqCreate',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminFaqEditor.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Create FAQ"
                }
            },
            {
                path: 'faq/edit/:id',
                name: 'AdminFaqEditor',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminFaqEditor.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Edit FAQ"
                }
            },
            {
                path: 'definitions',
                name: 'AdminDefinitionsList',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminDefinitionList.vue'),
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Definitions"
                }
            },
            {
                path: 'definitions/edit',
                name: 'AdminDefinitionCreate',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminDefinitionEditor.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Create Definition"
                }
            },
            {
                path: 'definitions/edit/:id',
                name: 'AdminDefinitionEdit',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminDefinitionEditor.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true,
                    title: "Admin - Edit Definition"
                }
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        // ESlint is a bit of a nanny sometimes
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.title = `${nearestWithTitle.meta.title} - Chat Libs!`;
    }

    if (to.matched.some(record => record.meta?.requiresAuth)) {
        // this route requires auth, check if logged in. If not, redirect to login page.
        if (!store.getters.isLoggedInOrPending) {
            next({name: 'Login'})
        } else {
            if (to.matched.some(record => record.meta?.requiresAdmin) && !store.getters.isAdmin) {
                // wait 500ms for the login to resolve before kicking the user back to the regular dashboard
                setTimeout(() => {
                    if (!store.getters.isAdmin) {
                        next({name: 'ManagementDashboard'})
                    } else {
                        next() // go to wherever I'm going
                    }
                }, 500)
            } else {
                next() // go to wherever I'm going
            }
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

export default router
