import store from "@/store";
import axios from "axios";


export function refresh() {
    store.commit('loginPending')

    const refresh_token = localStorage.getItem('refresh_token')
    if (!refresh_token) {
        // No refresh token to be found anywhere
        return store.dispatch('recordFailedLogin').then(() => {
            return Promise.reject()
        })
    }

    return axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/auth/refresh`,
        {
            refreshToken: refresh_token,
        }
    ).then((response) => {
        return store.dispatch('login', {
            refreshToken: response.data.refreshToken,
            authToken: response.data.authToken,
            meta: response.data.meta
        })
    }).catch((reason) => {
        return store.dispatch('recordFailedLogin').then(() => {
            return Promise.reject()
        })
    })
}

export function logout() {
    return store.dispatch('logout')
}
