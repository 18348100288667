<template>
    <div class="va-page-not-found justify--center pb-5">
        <div class="va-page-not-found__inner align--center">
            <div class="va-page-not-found__title text--center mb-4">
                <img alt="Oops!" src="@/assets/oops.svg" width="300" /><br/>
                404
            </div>
            <div class="va-page-not-found__text px-4 text--center">
                <span>We're not sure what you were looking for, but it isn't here</span>
            </div>
            <slot/>
            <va-button :to="{ name: 'Home' }">Take me home</va-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<style lang="scss">
.va-page-not-found {
    display: flex;
    padding-top: 5%;
    position: relative;
    // @include media-breakpoint-down(sm) {
    //   padding-top: 8%;
    // }
    &__inner {
        display: flex;
        flex-direction: column;
        max-width: 100%;

        .va-icon-vuestic {
            width: 19rem;
            height: 2rem;
            margin-bottom: 13%;
        }
    }

    &__title {
        font-size: 5rem;
        line-height: 1.25em;
        font-weight: 500;
        // @include media-breakpoint-down(xs) {
        //   font-size: 1.5rem;
        // }
    }

    &__text {
        margin-bottom: 2.5rem;
    }
}
</style>