import {createStore} from 'vuex'
import createCache from 'vuex-cache';
import UserRepository from "@/repositories/UserRepository";
import ConfigRepository from "@/repositories/ConfigRepository";
import ChannelRepository from "@/repositories/ChannelRepository";

export default createStore({
    plugins: [createCache()],
    state: {
        isSidebarMinimized: false,
        userName: "",
        userId: "",
        channelId: "",
        channelName: "",
        userMetadata: {},
        token: null,
        isLoggedIn: false,
        isLoginPending: false,
        autoLoginFailed: false,
        editorFor: null,
        showFTUE: false,
        isAdmin: false,
        isEditing: false,
        configCache: {},
        channelConfigCache: {},
    },
    mutations: {
        updateSidebarCollapsedState(state, isSidebarMinimized) {
            state.isSidebarMinimized = isSidebarMinimized
        },
        loginPending(state) {
            state.isLoginPending = true
        },
        login(state, payload) {
            state.isLoggedIn = true
            state.isLoginPending = false
            state.autoLoginFailed = false
            state.token = payload.token
            state.userName = payload.userName
            state.userId = payload.userId
            state.userMetadata = payload.meta
            state.showFTUE = payload.newUser
            state.isAdmin = payload.isAdmin

            // Don't update channelId/name if user is editing to prevent auto token refresh from kicking an editor off
            if (!state.isEditing) {
                state.channelId = payload.userId
                state.channelName = payload.userName
            }
        },
        logout(state) {
            state.isLoggedIn = false
            state.userName = ""
            state.userId = ""
            state.channelId = ""
            state.channelName = ""
            state.token = null
            state.userMetadata = {}
            state.isAdmin = false
            state.isEditing = false
        },
        recordFailedLogin(state) {
            state.isLoggedIn = false
            state.userId = ""
            state.channelId = ""
            state.channelName = ""
            state.autoLoginFailed = true
            state.isLoginPending = false
            state.token = null
            state.isAdmin = false
        },
        onboardingShown(state) {
            state.showFTUE = false
        },
        setEditorFor(state, payload) {
            state.editorFor = payload
        },
        setChannel(state, payload) {
            state.isEditing = payload.id != state.userId
            state.channelId = payload.id
            state.channelName = payload.name
        },
        setConfig(state, payload) {
            // @ts-ignore
            state.configCache[payload.key] = payload.value
        },
        setChannelConfig(state, payload) {
            // @ts-ignore
            state.channelConfigCache[payload.key] = payload.value
        }
    },
    actions: {
        login({commit}, payload) {
            try {
                const jwtPayload = JSON.parse(atob(payload.authToken.split('.')[1]))

                localStorage.setItem('refresh_token', payload.refreshToken)
                commit('login', {
                    token: payload.authToken,
                    userId: jwtPayload.userId,
                    userName: jwtPayload.userName,
                    displayName: jwtPayload.displayName,
                    isAdmin: jwtPayload.isAdmin || false,
                    meta: payload.meta,
                    newUser: payload.newUser,
                })
            } catch (err) {
                console.error(err)
                return null
            }
        },
        logout({commit}) {
            localStorage.removeItem('refresh_token')
            return commit("logout")
        },
        recordFailedLogin({commit}) {
            return commit("recordFailedLogin")
        },
        editorFor({commit, state}) {
            if (state.editorFor) {
                return state.editorFor
            }
            return UserRepository.getEditorFor().then((res) => {
                commit('setEditorFor', res.data.channels)

                const switchTo = localStorage.getItem('switchTo')
                if (switchTo) {
                    const switchToPayload = JSON.parse(switchTo)
                    if (res.data.channels.some((channel: any) => channel.id === switchToPayload.id)) {
                        commit('setChannel', switchToPayload)
                    }
                }

                return res.data.channels
            }).catch(() => {
                commit('setEditorFor', [])
                return []
            })
        },
        channelConfig({commit, state}, payload: {force?: boolean, channelId: string}) {
            // @ts-ignore
            if (!payload.force && state.channelConfigCache[payload.channelId]) {
                // @ts-ignore
                return state.channelConfigCache[payload.channelId]
            }

            return ChannelRepository.getConfig(payload.channelId).then((res) => {
                commit('setChannelConfig', {key: payload.channelId, value: res.data})
                return res.data
            }).catch(() => {
                return []
            })
        },
        config({commit, state}, configKey) {
            // @ts-ignore
            if (state.configCache[configKey]) {
                // @ts-ignore
                return state.configCache[configKey]
            }

            return ConfigRepository.get(configKey).then((res) => {
                commit('setConfig', {key: configKey, value: res.data.value})
                return res.data.value
            }).catch(() => {
                return []
            })
        },
        switchChannel({commit, state}, payload) {
            if (payload.rememberSwitch && payload.channel.id != state.userId) {
                localStorage.setItem('switchTo', JSON.stringify(payload.channel))
            } else {
                localStorage.removeItem('switchTo')
            }
            return commit('setChannel', payload.channel)
        }
    },
    getters: {
        isLoggedInOrPending(state) {
            return state.isLoggedIn || state.isLoginPending
        },
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        isAdmin(state) {
            return state.isAdmin
        },
        isEditing(state) {
            return state.isEditing
        },
        isEditorForOthers(state) {
            // @ts-ignore
            return state && state.editorFor && state.editorFor.length > 0
        }
    },
    modules: {}
})
