import Client from '@/repositories/AxiosClient';
import qs from "qs";
const resource = '/core/channel';

export default {
    getConfig(channelId: string) {
        return Client().get(`${resource}/${channelId}/config`);
    },
    putConfig(channelId: string, config: any) {
        return Client().put(`${resource}/${channelId}/config`, config);
    },
    getActiveStory(channelId: string) {
        return Client().get(`${resource}/${channelId}/story/@active`);
    },
    getStoryHistory(channelId: string, direction?: string, start?: string) {
        const query = qs.stringify({limit: 25, direction: direction, start: start})
        return Client().get(`${resource}/${channelId}/story?${query}`);
    },
    getStory(channelId: string, storyStateId: string,) {
        return Client().get(`${resource}/${channelId}/story/${storyStateId}`);
    },
    updateStory(channelId: string, storyStateId: string, body: any) {
        return Client().put(`${resource}/${channelId}/story/${storyStateId}`, body);
    },
    getPlaylist(channelId: string) {
        return Client().get(`${resource}/${channelId}/playlist`)
    },
    addToPlaylist(channelId: string, body: any) {
        return Client().put(`${resource}/${channelId}/playlist`, body)
    },
    replacePlaylist(channelId: string, body: any) {
        return Client().post(`${resource}/${channelId}/playlist`, body)
    },
    moderateSubmission(channelId: string, storyStateId: string, submissionId: string, disposition: string, text?: string) {
        return Client().put(`${resource}/${channelId}/story/${storyStateId}/moderate`, {
            id: submissionId,
            disposition: disposition,
            text: text
        });
    },
    generateTts(channelId: string, storyStateId: string) {
        return Client().put(`${resource}/${channelId}/story/${storyStateId}/tts`);
    },
    setActiveStory(channelId: string, body: any) {
        return Client().post(`${resource}/${channelId}/story`, body);
    },
    sendTestAlert(channelId: string, body: any) {
        return Client().post(`${resource}/${channelId}/alert/test`, body);
    },
    getEditors(channelId: string) {
        return Client().get(`${resource}/${channelId}/editors`);
    },
    updateEditors(channelId: string, body: any) {
        return Client().put(`${resource}/${channelId}/editors`, body);
    },
};
