<template>
    <div class="app-layout">
        <navbar :userName="userName" :isLoggedIn="isLoggedIn" :profile-image-url="profileImageUrl"/>
        <router-view :key="this.$route.path" v-if="showApp"/>
    </div>

</template>

<script>
import Navbar from '@/components/Navbar.vue'

import {computed, ref} from 'vue';
import {useStore} from 'vuex';

import {refresh} from "@/repositories/AuthHelper";

export default {
    name: 'app-layout',
    components: {
        Navbar
    },
    setup() {
        const store = useStore()
        const userName = computed(() => store.state.userName)
        const isLoggedIn = computed(() => store.state.isLoggedIn)
        const profileImageUrl = computed(() => store.state.userMetadata.profileImageUrl)

        const showApp = ref(true)
        if (!store.state.isLoggedIn) {
            refresh()
                .then(() => store.dispatch('editorFor'))
                .catch(() => {console.debug("Auto-login failed")})
        }

        return {
            userName,
            isLoggedIn,
            showApp,
            profileImageUrl
        }
    },
}
</script>

<style lang="scss">
#app {
    font-family: 'Poppins', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    line-height: 1.25em;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;
.app-layout {
    height: 100vh;
    display: flex;
    flex-direction: column;

    &__navbar {
        min-height: 4rem;
    }

    &__content {
        display: flex;
        height: calc(100vh - 4rem);
        flex: 1;
        @media screen and (max-width: $tabletBreakPointPX) {
            height: calc(100vh - 6.5rem);
        }

        .app-layout__sidebar-wrapper {
            position: relative;
            height: 100%;
            background: var(--va-white);
            @media screen and (max-width: $tabletBreakPointPX) {
                &:not(.minimized) {
                    width: 100%;
                    height: 100%;
                    position: fixed;
                    top: 0;
                    z-index: 999;
                }
                .va-sidebar:not(.va-sidebar--minimized) {
                    // Z-index fix for preventing overflow for close button
                    z-index: -1;

                    .va-sidebar__menu {
                        padding: 0;
                    }
                }
            }
        }
    }

    &__page {
        flex-grow: 2;
        overflow-y: scroll;
    }
}

h1.details-title {
    font-size: 24px;
    text-align: left;
}

.va-card {
    &__title {
        font-size: 16px !important;
    }
}

.va-modal__title {
    font-size: 1.5em;
}

.va-button {
    border-radius: 5px !important;
}

// Our fake 'abbr' element
span[title] {
    cursor: help;
    border-bottom: 1px dotted #d7d7d7;
    text-decoration: none;
}

// Fix some bad font selectors
.va-select-option-list .no-options {
    font-family: 'Poppins', Avenir, Helvetica, Arial, sans-serif;
}
.va-select-option-list__option {
    font-family: 'Poppins', Avenir, Helvetica, Arial, sans-serif;
}
:root {
    --va-font-family: 'Poppins', Avenir, Helvetica, Arial, sans-serif !important;
    --va-list-item-label-overflow: display;
}
</style>
