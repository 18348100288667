<template>
    <span v-if="datetime" :title="parsedTimestamp.utc().format('lll [UTC]')">
        {{ parsedTimestamp.format(format) }}
    </span>
    <span v-else>{{ placeholder }}</span>
</template>

<script>
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'

export default {
    name: 'AppTimestamp',
    props: {
        datetime: String,
        placeholder: String,
        format: {
            type: String,
            default: 'lll'
        }
    },
    computed: {
        parsedTimestamp() {
            dayjs.extend(utc)
            dayjs.extend(localizedFormat)
            return dayjs(this.datetime)
        }
    }
}
</script>

<style scoped lang="scss">
</style>
