<template>
    <va-dropdown
        v-model="isShown"
        :offset="[-75, 13]"
        boundary-body
        class="profile-dropdown"
        position="bottom"
    >
        <template #anchor>
            <span class="profile-dropdown__anchor">
                <slot/>
            </span>
        </template>
        <va-dropdown-content class="profile-dropdown__content">
            <va-list-item>
                <va-avatar class="mr-2" size="small" :src="profileImageUrl"></va-avatar>
                {{ userName }}
            </va-list-item>

            <va-list-separator/>

            <va-list-item
                v-for="option in items"
                :key="option.name"
                @click="goto(option.redirectTo)"
            >
                <va-icon size="small" :name="option.icon" class="mr-2" />
                {{ option.name }}
            </va-list-item>

            <va-list-separator v-if="options.length > 0"/>

            <va-list-item
                v-for="option in options"
                :key="option.name"
                @click="goto(option.redirectTo)"
            >
                <va-icon size="small" :name="option.icon" class="mr-2" />
                {{ option.name }}
            </va-list-item>

            <va-list-separator/>

            <va-list-item @click="logout">
                <va-icon size="small" name="logout" class="mr-2" />
                Log Out
            </va-list-item>
        </va-dropdown-content>
    </va-dropdown>
</template>

<script>
import {useGlobalConfig} from 'vuestic-ui'
import {mapState} from "vuex";

export default {
    name: 'profile-section',
    data() {
        return {
            items: [
                {
                    name: 'Dashboard',
                    icon: 'dashboard',
                    redirectTo: 'ManagementDashboard',
                },
                {
                    name: 'Channel Settings',
                    icon: 'settings',
                    redirectTo: 'ChannelConfig',
                },
            ],
            isShown: false,
        }
    },
    props: {
        userName: String,
        profileImageUrl: String
    },
    computed: {
        theme() {
            return useGlobalConfig().getGlobalConfig()
        },
        options() {
            const items = []
            if (this.$store.getters.isEditorForOthers) {
                items.push({
                    name: 'Switch Channels',
                    icon: 'switch_account',
                    redirectTo: 'ChannelSwitch'
                })
            }
            if (this.isAdmin) {
                items.push({
                    name: 'Admin Dashboard',
                    icon: 'admin_panel_settings',
                    redirectTo: 'AdminDashboard'
                })
            }

            return items
        },
        ...mapState(['isAdmin'])
    },
    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push({name: 'Home'})
            })
        },
        goto(route) {
            this.$router.push({name: route})
        }
    }
}
</script>

<style scoped lang="scss">
.va-list-item {
    cursor: pointer;
    color: #676767;

    :hover {
        background-color: transparentize(#00ADEE, 0.7);
    }
}

.profile-dropdown {
    cursor: pointer;

    .va-dropdown-popper__anchor {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        width: 15rem;

        .va-list-separator {
            margin: 0.25em 0 0.25em 0;
        }
    }

    .va-dropdown__anchor {
        display: inline-block;
    }
}
</style>
